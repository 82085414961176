











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question524',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        language: 'fr',
      },
      options: [
        {text: 'E', value: 'E'},
        {text: 'Z', value: 'Z'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q524.png';
    },
  },
};
